(<template>
  <page-data-section :title="sectionTitle"
                     :progress="isProgressActive"
                     class="comp-info-edit">
    <div v-if="!userCanEditInfo">
      <p class="comp-info-edit__sorry-message">{{ $gettext('Unfortunately you have no access to edit company information.') }}</p>
    </div>
    <div v-if="userCanEditInfo"
         class="comp-info-edit__content-wrapper">
      <info-form :full-form="true"
                 :disabled-org-number="true"
                 class="comp-info-edit__form"
                 @formstarted="isProgressActive = true"
                 @stopprogress="isProgressActive = false"
                 @formcompleted="completeForm" />
      <div class="comp-info-edit__avatar-label-wrapper">
        <label class="comp-info-edit__avatar-label">
          <company-avatar :rotation="logoRotation"
                          :avatar="logoUrl"
                          class="comp-info-edit__avatar" />
          <span class="comp-info-edit__avatar-label-text">{{ $gettext('Upload picture') }}</span>
          <input class="is-hidden"
                 type="file"
                 accept="image/*"
                 @change="changeLogoLogic">
        </label>
        <div v-if="logoErrors && logoErrors.length"
             class="comp-info-edit__avatar-errors">
          <p v-for="(error, index) in logoErrors"
             :key="index">{{ error }}</p>
        </div>
        <button v-if="logoUrl"
                class="comp-info-edit__rotate-btn"
                @click="setLogoRotation"></button>
      </div>
    </div>
  </page-data-section>
</template>)

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import EnterpriseInfoForm from '@/components/enterprise/EnterpriseInfoForm';

  export default {
    asyncData({store}) {
      const myEntId = store.getters['UserInfoStore/enterpriseId'];
      const canEdit = store.getters['UserInfoStore/userIsAdminOfEnterprise'];
      let promiseArr = '';
      if (canEdit && !store.state.TTEnterpriseInfoStore.companyInfo) {
        promiseArr = [...promiseArr, store.dispatch('TTEnterpriseInfoStore/getEnterprise', myEntId)];
      }

      return promiseArr ? Promise.all(promiseArr) : promiseArr;
    },
    components: {
      'page-data-section': PageDataSection,
      'info-form': EnterpriseInfoForm
    },
    data() {
      return {
        isProgressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid', 'userIsAdminOfEnterprise']),
      ...mapState('TTEnterpriseInfoStore', {
        logoRotation: (state) => state.form.logoRotation,
        logoUrl: (state) => state.form.logoUrl,
        logoErrors: (state) => state.errors.logo.errors,
        enterpriseOwnerUid: (state) => state.companyInfo.owner_uid || ''
      }),
      userCanEditInfo() { return this.userIsAdminOfEnterprise || this.userIsOwnerOfEnterprise || this.$isGodmode(); },
      userIsOwnerOfEnterprise() { return this.userUid == this.enterpriseOwnerUid; },
      sectionTitle() { return this.$gettext('Company information'); }
    },
    methods: {
      ...mapMutations('TTEnterpriseInfoStore', [
        'setError',
        'removeOneError',
        'setLogoRotation',
        'setLogoUrl',
        'setLogoFile',
        'prefillEditForm',
        'resetLogoRotation'
      ]),
      changeLogoLogic(eve) {
        this.removeOneError('logo');
        this.resetLogoRotation();

        if (eve && eve.target && eve.target.files && eve.target.files[0]) {
          const converterResponse = this.$avatarConverter.convertAvatar(this, eve.target.files[0]);
          if (!(converterResponse.then) && Array.isArray(converterResponse)) {
            for (const error of converterResponse) {
              this.setError({
                fieldName: 'logo',
                errorText: error
              });
            }
          } else {
            converterResponse.then((response) => {
              this.setLogoUrl(response.target.result);
              this.setLogoFile(eve.target.files[0]);
            });
          }
        }
      },
      completeForm() {
        this.$router.push(this.$makeRoute({name: 'BuyerCompanyInfo'}));
      }
    },
    beforeMount() {
      this.prefillEditForm();
    }
  };
</script>

<style>
  .comp-info-edit .comp-info-edit__form .info-form__btns-wrapper {
    justify-content: flex-start;
    margin-top: 20px;
  }

  .comp-info-edit .comp-info-edit__form .info-form__btn--cancel {
    display: none;
  }
</style>

<style scoped>
  .comp-info-edit__content-wrapper {
    display: flex;
  }

  .comp-info-edit__form.info-form {
    max-width: 380px;
    margin: 0;
    padding-right: 20px;
  }

  .comp-info-edit__avatar-label-wrapper {
    position: relative;
    display: block;
    width: 100px;
    margin: 18px auto;
  }

  .comp-info-edit__avatar-label {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  .comp-info-edit__avatar {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }

  .comp-info-edit__avatar-label-text {
    display: block;
    width: 100%;
    color: #ff5b24;
    color: var(--color-secondary);
    text-align: center;
  }

  .comp-info-edit__avatar-errors {
    display: block;
    width: 100%;
    margin-top: 10px;
    padding: 0 10px;
    color: #f04;
    color: var(--color-error);
    font-size: 12px;
    text-align: left;
  }

  .comp-info-edit__rotate-btn {
    position: absolute;
    top: 75px;
    right: -20px;
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-image: url(~@assets/imgs/undefined_imgs/rotate_button_default.svg);
    background-image: var(--image-rotate-button);
    background-position: 50% 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  @media (max-width: 600px) {
    .comp-info-edit__content-wrapper {
      flex-direction: column-reverse;
    }

    .comp-info-edit__form.info-form {
      width: 100%;
      max-width: initial;
      padding: 0;
    }

    .comp-info-edit__avatar-label-wrapper {
      margin: 0;
      margin-bottom: 40px;
    }
  }
</style>
