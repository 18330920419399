// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/rotate_button_default.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.comp-info-edit__content-wrapper[data-v-c7af7a18] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.comp-info-edit__form.info-form[data-v-c7af7a18] {\n  max-width: 380px;\n  margin: 0;\n  padding-right: 20px;\n}\n.comp-info-edit__avatar-label-wrapper[data-v-c7af7a18] {\n  position: relative;\n  display: block;\n  width: 100px;\n  margin: 18px auto;\n}\n.comp-info-edit__avatar-label[data-v-c7af7a18] {\n  display: block;\n  width: 100%;\n  cursor: pointer;\n}\n.comp-info-edit__avatar[data-v-c7af7a18] {\n  width: 100px;\n  height: 100px;\n  margin: 0 auto;\n}\n.comp-info-edit__avatar-label-text[data-v-c7af7a18] {\n  display: block;\n  width: 100%;\n  color: #ff5b24;\n  color: var(--color-secondary);\n  text-align: center;\n}\n.comp-info-edit__avatar-errors[data-v-c7af7a18] {\n  display: block;\n  width: 100%;\n  margin-top: 10px;\n  padding: 0 10px;\n  color: #f04;\n  color: var(--color-error);\n  font-size: 12px;\n  text-align: left;\n}\n.comp-info-edit__rotate-btn[data-v-c7af7a18] {\n  position: absolute;\n  top: 75px;\n  right: -20px;\n  display: block;\n  width: 25px;\n  height: 25px;\n  border-radius: 50%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-rotate-button);\n  background-position: 50% 50%;\n  background-size: 100% auto;\n  background-repeat: no-repeat;\n}\n@media (max-width: 600px) {\n.comp-info-edit__content-wrapper[data-v-c7af7a18] {\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: reverse;\n        -ms-flex-direction: column-reverse;\n            flex-direction: column-reverse;\n}\n.comp-info-edit__form.info-form[data-v-c7af7a18] {\n    width: 100%;\n    max-width: initial;\n    padding: 0;\n}\n.comp-info-edit__avatar-label-wrapper[data-v-c7af7a18] {\n    margin: 0;\n    margin-bottom: 40px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
